import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSidebarOpen &&
        !event.target.closest("#sidebar") &&
        !event.target.closest("#hamburger")
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const handleClickEvent = (item) => {
    setActiveItem(item);
    setIsSidebarOpen
    
    (false);
  
    const section = document.getElementById(item);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 64, 
        behavior: "smooth"
      });
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`fixed top-0 left-0 right-0 p-2 z-[999] font-nunito
      ${isScrolled ? "bg-[#1b2037] bg-opacity-50" : ""}`}
    >
      <div className="flex justify-between  sm:justify-around sm:ml-20 sm:mr-20 sm:pt-3">
        <div className="flex justify-center items-center min-h-[64px] w-full sm:w-auto">
        <img src="/images/logo1.webp" alt="Logo" style={{ height: '70px' }}  className="rounded-full lg:flex hidden"/>
          <Link
            to="/"
            onClick={() => setIsSidebarOpen(false)}
            style={{ cursor: "pointer" }}
          ></Link>
        </div>
        <nav className="hidden sm:flex justify-center sm:justify-start items-center">
          <ul className="justify-start  hidden sm:flex space-x-6 text-white text-[20px]">
            {["Home", "Services", "Contact", "About"].map((item) => (
              <li
                key={item}
                onClick={() => handleClickEvent(item)}
                className={`cursor-pointer ${
                  activeItem === item ? "border-b-2 border-white font-bold" : ""
                }`}
              >
                {item}
              </li>
            ))}
            <a href="tel:+919010060007"><button className="bg-orange-400  text-white w-32 cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-orange-400 ">
              Call now
            </button></a>
          </ul>
        </nav>
        <div id="hamburger" className="sm:hidden flex items-center">
          <FaBars
            onClick={toggleSidebar}
            className='cursor-pointer text-white'
            size={24}
          />
        </div>
      </div>

      <div
        id="sidebar"
        className={`fixed top-0 left-0 h-full justify-center items-center w-64 backdrop-filter backdrop-blur-md transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-[1000]`}
      >
        <div className="flex justify-end p-4">
          <FaTimes
            onClick={toggleSidebar}
            className="text-white cursor-pointer"
            size={24}
          />
        </div>
        <ul className="space-y-4 mt-10 text-white text-lg p-4">
          {["Home", "Services", "Contact", "About"].map((item) => (
            <li
              key={item}
              onClick={() => handleClickEvent(item)}
              className={`cursor-pointer ${
                activeItem === item ? "font-bold" : ""
              }`}
            >
              {item}
            </li>
          ))}
           <a href="tel:+919010060007"><button className="bg-orange-400 mt-6 text-white w-32 cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-orange-400">
              Call now
            </button></a>
        </ul>
      </div>
    </div>
  );
}
