import React, { useState } from 'react';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const FloatingButton = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={toggleMenu}
        className="bg-orange-400 text-white p-4 rounded-full shadow-lg hover:bg-orange-400 focus:outline-none"
      >
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>

        )}
      </button>
      {isOpen && (
        <div className="mt-2 space-y-2">
          <a
            href="https://wa.me/9010060007"
            target="_blank"
            rel="noopener noreferrer"
            className="block bg-orange-400 text-white p-4 rounded-full shadow-lg hover:bg-orange-400"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="h-6 w-6 mx-auto" fill="currentColor">
              <path d="M16 0C7.164 0 0 7.164 0 16c0 3.109.801 6.047 2.325 8.654L.152 31.61c-.116.334-.03.702.227.95.197.193.454.3.723.3.1 0 .2-.014.299-.043l6.25-1.871C9.892 31.268 12.913 32 16 32c8.837 0 16-7.164 16-16 0-8.837-7.163-16-16-16zm8.267 22.328c-.344.964-1.699 1.75-2.428 1.871-.645.104-1.473.152-2.341-.148-.538-.18-1.24-.404-2.132-.789-3.758-1.623-6.207-5.365-6.392-5.62-.181-.247-1.527-2.033-1.527-3.875 0-1.84.963-2.753 1.307-3.098.344-.347.753-.434 1.006-.434.247 0 .503.004.723.012.234.01.546-.088.852.647.334.775 1.144 2.78 1.244 2.98.099.199.17.438.034.683-.133.247-.199.434-.398.666-.198.234-.418.52-.598.693-.198.199-.403.414-.173.812.23.398 1.018 1.676 2.188 2.714 1.506 1.346 2.777 1.766 3.174 1.958.398.198.627.165.853-.099.224-.267 1.048-1.188 1.328-1.594.278-.399.553-.332.93-.198.38.134 2.394 1.129 2.805 1.335.41.203.681.298.78.466.096.165.096.964-.248 1.927z" />
            </svg>
          </a>
          <a
            href="tel:9010060007"
            className="block bg-orange-400 text-white p-4 rounded-full shadow-lg hover:bg-orange-400"
          >
            <LocalPhoneOutlinedIcon />
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingButton;
