import React from "react";

function Service() {
  return (
    <>
      <div id="Services" className="mt-12">
        <h2 className="text-center text-2xl md:text-5xl font-bold">Services</h2>
        <div id="services" className="w-full flex justify-center py-12">
          <div className="lg:w-[80%] w-[90%] lg:flex md:flex lg:gap-16 gap-16">
            <div className="sm:w-[50%] p-4 shadow-lg rounded-lg text-left">
              <img
                src="/images/servicesmain.webp"
                alt="Service photo"
                className="rounded-lg"
              />
              <p className="mt-2 text-lg">We buy Gold</p>
              <p className="mt-2 font-sans">
              Ready to turn your gold into cash? Contact us today for a fast, fair, and straightforward sale. Get the best value for your gold with our expert team.
              </p>
              <div className="mt-2">
              <a href="tel:+919010060007"><button className="border border-black text-black w-32 cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-black hover:text-white hover:transition hover:duration-300">
                  Call now
                </button></a>
              </div>
            </div>

            <div className="sm:w-[50%] p-4 shadow-lg rounded-lg text-left sm:pt-4 pt-6">
              <img
                src="/images/servicesmain3.webp"
                alt="Service photo "
                className="rounded-lg"
              />
              <p className="mt-2 text-lg">Release Pledge Gold</p>
              <p className="mt-4">
              Release your pledged gold with ease! Contact us for fast, professional help to access its full cash value.
              </p>
              <div className="mt-2">
              <a href="tel:+919010060007"><button className="border border-black text-black w-32 cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-black hover:text-white hover:transition hover:duration-300">
                  Call now
                </button></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
