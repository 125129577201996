import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

import Mainpage from './pages/home';
import ThankyouPage from './pages/thankyouPage';

import Navbar from './components/Navbar';
// import Services from './pages/sellGold';
// import Sellgold from './pages/sellgold';
// import Release from './pages/releasegold';
// import Renting from './pages/rentinggold';


function App() {
  return (
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/thank-you" element={<ThankyouPage />} />
          {/* <Route path="/sell-gold" element={<Sellgold />} />
          <Route path="/release-gold" element={<Release />} />
          <Route path="/stone-value" element={<Renting />} /> */}
        </Routes>
      </>
  );
}

export default App;
