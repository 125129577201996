import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Contact() {
    const navigate = useNavigate()
    const handleLinkClick = (event, sectionId) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: "smooth" });
    };

    const {
        register,
        handleSubmit, reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log(data);
        const formData = new FormData()
        formData.append('name',data.full_name);
        formData.append('phone',data.phone_number);
        formData.append('weight',data.gram);
        formData.append('gold_purity',data.gold_purity);
        try {
            const response = await axios.post('https://bestgoldbuying.com/leads/submit.php',formData,{withCredentials : false})
          if(response.status === 200){
            // alert("Thanks you for contacting Best Gold Buying. Our Team will get back to you as soon as possible.")
            navigate('/thank-you')
          }
        } catch (error) {
            console.log(error);
        }
        navigate('/thank-you');
        reset(); 
    };

    return (
        <div id="Contact" className="">
            <div className="bg-[#f0d5c4] mt-10">
                <div className="py-8 lg:py-16 px-4 mx-auto my-auto">
                    <h2 className="mb-4 text-center text-2xl md:text-5xl font-bold">
                        Contact Us
                    </h2>
                    <p className="mb-8 lg:mb-16 font-light text-center sm:text-xl">
                       
                    </p>
                    <div className="flex justify-center w-full">
                        <div className="lg:w-[80%] w-[95%] lg:flex lg:gap-12">
                            <div className="lg:w-[50%] lg:p-6 w-full">
                                <img src="images/servicesmain2.webp" alt="Contact Us" className="rounded-lg"/>
                            </div>
                            <div className="lg:w-[50%] lg:pt-0 pt-6">
                                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                                    <div>
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-medium text-gray-700 font-sans"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                            {...register("full_name", { required: "Name is required" })}
                                        />
                                        {errors.full_name && (
                                            <span className="text-red-500 text-xs font-sans">
                                                Name is required
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="phone"
                                            className="block text-sm font-medium text-gray-700 font-sans"
                                        >
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            id="phone"
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                            {...register("phone_number", {
                                                required: "Phone number is required",
                                                minLength: {
                                                    value: 10,
                                                    message: "Phone number must be exactly 10 digits",
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Phone number must be exactly 10 digits",
                                                },
                                                pattern: {
                                                    value: /^[6-9]\d{9}$/,
                                                    message: "Invalid phone number",
                                                },
                                            })}
                                        />
                                        {errors.phone_number && (
                                            <span className="text-red-500 text-xs">
                                                {errors.phone_number.message}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="gram"
                                            className="block text-sm font-medium text-gray-700 font-sans"
                                        >
                                            Grams
                                        </label>
                                        <input
                                            type="number"
                                            id="gram"
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                            {...register("gram")}
                                        />
                                        {errors.gram && (
                                            <span className="text-red-500 text-xs">
                                                {errors.gram.message}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="gold_purity"
                                            className="block text-sm font-medium text-gray-700 font-sans"
                                        >
                                            Gold Purity
                                        </label>
                                        <select
                                            id="gold_purity"
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 rounded-md focus:outline-none"
                                            {...register("gold_purity")}
                                        >
                                            <option value="">Select Purity</option>
                                            <option value="24K">24K</option>
                                            <option value="22K">22K</option>
                                            <option value="18K">18K</option>
                                            <option value="14K">14K</option>
                                        </select>
                                        {errors.gold_purity && (
                                            <span className="text-red-500 text-xs">
                                                {errors.gold_purity.message}
                                            </span>
                                        )}
                                    </div>
                                    <button className="border border-black text-black w-32 cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-black hover:text-white hover:transition hover:duration-300">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
