import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import About from "../components/About";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Service from "../components/Service";
import FloatingButton from "../components/floatingButtons";


function Home() {
  return (
    <>
    <Hero />
    <About />
    <Service />
    <div id="contact"><Contact /></div>
    <Footer />
    <FloatingButton />
</>
  );
}

export default Home;
