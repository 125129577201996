import React from "react";
import { useNavigate } from "react-router-dom";
function ThankyouPage() {
    const navigate = useNavigate()
  return (
    <div className="pt-[10%] sm:pt-0  min-h-screen w-full flex flex-col items-center justify-center bg-[#d3be97] ">
      <div className="w-full sm:w-[100%] lg:w-[85%] mt-10 px-4 flex flex-col lg:flex-row justify-center items-center gap-5">
        <div className="w-[90%] sm:w-[90%] md:w-[60%] lg:w-[35%] p-5 flex justify-center items-center bg-gradient-to-t from-[#06064C] to-[#11113F] ">
          <div className="p-3 w-full flex flex-col items-center">
          <div className="flex justify-center items-center min-h-[64px] w-full sm:w-auto">
        <img src="/images/logo1.webp" alt="Logo" style={{ height: '90px' }}  className="rounded-full lg:flex hidden"/>
      
        </div>
            <h2 className="text-white text-[23px] sm:text-[30px] font-bold text-center">
            Thank you for contacting Best Gold Buying.
            </h2>
            <p className="sm:mt-5 text-[14px] sm:text-[18px] lg:text-[17px] text-white mb-5 text-center leading-8">
            Our Team will get back to you as soon as possible.
            </p>
            <button className="font-bold bg-gradient-to-r from-ajavu-gradient-orange-start to-ajavu-orange text-white pr-8 pl-8 pt-3 pb-3 rounded-full hover:from-ajavu-orange hover:to-ajavu-gradient-orange-start text-[15px] mt-3 w-[90%] sm:w-[60%] max-w-xs mb-3 mx-auto"
            onClick={()=> navigate('/')}
            >Back Home</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankyouPage;
