import React from "react";
import TypingEffect from "react-typing-effect";
import ContactForm from "./ContactForm";
import CallIcon from "@mui/icons-material/Call";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const Hero = () => {
  const styles = {
    backgroundBlendMode: "overlay",
    backgroundImage: `url('/images/hero.webp'), linear-gradient(117deg, #0a0202 15%, rgba(255, 255, 255, 0) 59%)`,
    backgroundSize: "cover",
    backgroundPosition: "65% center",
  };

  const handleLinkClick = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      id="Home"
      className="flex h-[100vh] sm:h-screen justify-center flex-col sm:bg-right md:bg-center"
      style={styles}
    >
      {/* <img
        className="w-screen h-screen bg-cover bg-center shrink-0"
        src="/images/hero.webp"
        alt=""
        title="hero"
        loading="lazy"
      /> */}
      <div className="flex flex-col justify-center items-center p-8 md:space-y-4">
        <div className="w-full sm:w-[80%] flex flex-col bg-black/25 sm:bg-transparent p-4">
          <h1 className="text-[#ffffff] w-full lg:w-[50%] font-bold text-2xl sm:text-4xl xl:text-5xl">
            <TypingEffect
              text={["BEST GOLD BUYERS "]}
              speed={100}
              eraseSpeed={50}
              typingDelay={500}
              cursor={"|"}
            />
          </h1>
          <p className="flex text-[#ffffff] whitespace-pre-line w-full sm:w-1/2 mt-5">
          Searching for the best gold buyers? Our expert team provides exceptional service, ensuring top value for your gold. With transparent pricing and a focus on your satisfaction, we make selling gold smooth, efficient, and highly rewarding.
          </p>

          <div>
            <ContactForm />
          </div>
          <div className="flex gap-5 w-[80%] mt-5">
            <a href="tel:+919010060007">
              <CallIcon className="text-white" sx={{ fontSize: 32 }} />
            </a>
            <div className="flex items-center">
              <a onClick={(e) => handleLinkClick(e, 'contact')}>
                <button className="text-[#ffffff]">Contact Us</button>
              </a>
              <ArrowDownwardIcon
                className="animate-bounceUpDown cursor-pointer"
                sx={{ color: "white" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
