import React from "react";

function Footer() {

    const phoneNumber = '9010060007'
    const handlePhoneClick = () => {
      window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <div>

            <footer className=" pb-10 p-6 ">
                <div className="mx-auto lg:w-[70%] p-4 lg:py-8">
                    <div className="md:flex md:justify-start gap-16">
                        <div className=" lg:mr-6 mb-6 md:mb-0">
                            <a href="" className="flex items-center">
                                <img src="/images/logo1.webp" className="h-12 w-12 mr-3 rounded-full" alt="Best Gold Buyer" />
                                <span className="self-center text-2xl font-bold whitespace-nowrap ">Best Gold Buying</span>
                            </a>
                            <p className="max-w-xs mb-3 mt-6 text-gray-600 font-sans text-justify">Discover the best gold buying service with top value, fair pricing, and quick payments. Contact us for reliable, and rewarding gold-selling experience.</p>
                        </div>
                        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-10 md:gap-0 gap-0">
                            <div>
                                <h2 className="mb-6 text-sm font-bold text-gray-900 uppercase ">Quick Links</h2>
                                <ul className="text-gray-500  font-medium">
                                    <li className="mb-4">
                                        <a href="#Home" className="hover:underline">Home</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="#About" className="hover:underline">About</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="#Services" className="hover:underline">Service</a>
                                    </li>
                                   
                                    <li className="mb-4">
                                        <a href="#Contact" className="hover:underline">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        
                            <div>
                                <h2 className="mb-6 lg:text-sm text-sm font-bold text-gray-900 uppercase ">Contact Info</h2>
                                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                    <li className="mb-2">
                                        <a href="#" className="hover:underline lg:text-base md:text-xs text-base" onClick={handlePhoneClick}>+91 90100 60007</a>
                                    </li>
                                    <li className="">
                                        <a href="#" className="hover:underline lg:text-base md:text-xs text-sm">bestgoldbuying@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
                <div className="lg:flex lg:items-center lg:justify-center">
                    <div className="lg:w-[70%]">
                        <span className="text-sm text-gray-500 sm:text-center ">©2024 <a href="" className="hover:underline">Best Gold Buying</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;