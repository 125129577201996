import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { Dialog, DialogContent, TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const CustomerDialogForm = ({branch}) => {
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState()
  const [otpSent, setOtpSent] = useState(false)
  const [verified,setVerified] = useState(false);
  const [formSubmitted,setFormSubmitted] = useState(false)
  const navigate = useNavigate()
  const { control, handleSubmit, reset, watch, formState: { errors },setError,clearErrors } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      purity: "",
      gram: ""
    }
  });

  const e_otp = watch('otp')


  useEffect(() => {
    let firstTimeout;
    let secondTimeout;
  
    if (!verified) {
      firstTimeout = setTimeout(() => {
        setOpen(true);
        secondTimeout = setTimeout(() => {
          setOpen(true);
        }, 20000); 
      }, 10000); 
    }
  
    return () => {
      clearTimeout(firstTimeout); 
      clearTimeout(secondTimeout); 
    };
  }, [verified]); 

 


  useEffect(() => {
    if(e_otp?.length === 6){
      if (Number(otp) !== Number(e_otp)){
        setError('otp', { type: 'manual', message: 'Wrong OTP' });
        return;
      } 
      clearErrors('otp');
      setVerified(Number(otp) === Number(e_otp))
  }
  }, [e_otp, otp, setError, setVerified]);



  const onSubmit = async(data) => {
    const formData = new FormData()
    formData.append('name',data.name);
    formData.append('phone',data.phoneNumber);
    formData.append('weight',data.gram);
    formData.append('location',branch);
    formData.append('gold_purity',data.purity);
    try {
        const response = await axios.post('https://bestgoldbuying.com/leads/submit.php',formData,{withCredentials : false})
      setFormSubmitted(true)
      if(response.status === 200){
        // alert("Thanks you for contacting Best Gold Buying. Our Team will get back to you as soon as possible.")
        navigate('/thank-you')
      }
    } catch (error) {
      setFormSubmitted(true)
    }
    reset(); 
  };

  const handleClose = () => {
    setOpen(false);
    setOtpSent(false);
    setVerified(false);
    setOtp('')
    reset()
  };

  

  return (
    <Dialog  open={open}  onClose={handleClose}   fullWidth maxWidth="xs" 
    PaperProps={{sx: {borderRadius: '10px',  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', background: '#f7f7f7',position: 'relative', mt: 3,mb: 3,},}}>
    <IconButton   onClick={handleClose} sx={{position: 'absolute', right: 8,top: 8,  color: (theme) => theme.palette.grey[500],}}>
      <CloseIcon />
    </IconButton>
   
      <Typography variant="h6" component="h3" className="font-bold text-center text-xl font-nunito text-gray-800 my-4 pt-5" >
        Get in Touch with Trusted Gold Buying.
      </Typography>
      <DialogContent>
      <Box display="flex" flexDirection="column"  alignItems="center" justifyContent="center"  sx={{ width: '100%'}}>
        <Controller name="name" control={control}  render={({ field }) => <TextField {...field} autoFocus margin="dense" label="Name" type="text"  
        variant="outlined" sx={{ marginBottom: '15px', width:'90%' }} />}/>

        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: 'Phone Number is required',
            pattern: {
              value: /^[6-9]\d{9}$/,
              message: 'Invalid Phone Number',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              label="Phone"
              type="tel"
              variant="outlined"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              sx={{ marginBottom: '15px', width: '90%' }}
            />
          )}
        />


        <Controller name="purity" control={control}
          render={({ field }) => (
            <FormControl  margin="dense" variant="outlined" sx={{ marginBottom: '15px',width:'90%' }}>
              <InputLabel id="purity-label">Gold Purity</InputLabel>
              <Select {...field} labelId="purity-label" id="purity" label="Gold Purity">
                <MenuItem value={'18k'}>18k</MenuItem>
                <MenuItem value={'20k'}>20k</MenuItem>
                <MenuItem value={'22k'}>22k</MenuItem>
                <MenuItem value={'24k'}>24k</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller name="gram" control={control}
          render={({ field }) => <TextField {...field} margin="dense" label="Gram" type="number"  variant="outlined" sx={{ marginBottom: '15px',width:'90%' }} />}
        />
        <Button  onClick={handleSubmit(onSubmit)} 
          color="primary" variant="contained" 
          sx={{backgroundColor: '#066aab', color: 'white',  marginTop: '20px',
            padding: '10px 0',     borderRadius: '8px',width:'90%', mb:3}}
          className="custom-button-class" 
        >
          Submit
        </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerDialogForm;
