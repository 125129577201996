import React from "react";
import CallIcon from '@mui/icons-material/Call';
const About = () => {
  return (
    <div id="About" className="flex flex-col flex-wrap gap-5 p-7 justify-center items-center w-full bg-[#d3be97] pt-10 pb-10 md:pt-20 md:pb-20">
      <h2 className="flex-start text-2xl md:text-5xl font-bold">About Us</h2>

      <div className="flex flex-col w-full justify-center items-center gap-[4%] md:flex-row flex-wrap sm:w-[90%] mt-10 ">

        <div className="flex items-center md:w-[48%]">
          <img
            className="rounded-md h-80"
            src="./images/aboutPhoto.webp"
            alt="Classy Necklaces"
            title="Classy Necklaces"
            loading="lazy"
          ></img>
        </div>

        <div className="flex flex-col gap-5 md:w-[48%]">
          <p className="text-xl sm:text-xl lg:text-3xl font-bold mt-5  md:mt-0 text-justify">
            Looking for gold buyers <br/> in Bengaluru?
          </p>
          <p className="sm:text-xs lg:text-left font-sans text-justify">
            At Best Gold Buyers in Bangalore, we specialize in offering exceptional value and service for your gold. 
            Our commitment is to provide a transparent, trustworthy, and efficient gold-selling experience. 
            With years of expertise in the industry, we ensure competitive pricing, 
            instant cash and secure payments for all types of gold, including jewellery, coins, and bullion.<br />
            Our mission is to make the process as smooth as possible, with clear evaluations and no hidden fees. 
            We pride ourselves on our customer-focused approach, guaranteeing a hassle-free transaction and the best possible value for your gold. 
            Whether you're looking to sell old jewellery, we are here to help you every step of the way. 
            Experience a reliable and satisfying gold-selling process with our trusted services in Bangalore.
          </p>
          {/* <div>
          <button className="flex flex-col justify-start items-center font-bold">  Call Now</button>
          </div> */}
          <a href="tel:+919010060007"><button className="border-2 border-black text-black w-32  cursor-pointer h-10  rounded-md	text-lg font-bold hover:bg-black hover:text-white hover:transition hover:duration-300">
            Call now
          </button></a>
        </div>

      </div>
    </div>
  );
};

export default About;

